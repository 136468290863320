import * as React from 'react'
import { PropsWithChildren } from 'react'
import { UserMigrationState } from './state'
import { UserMigrationDispatch } from './reducer'

export const UserMigrationStateContext = React.createContext<
  UserMigrationState | undefined
>(undefined)

export const UserMigrationDispatchContext = React.createContext<
  UserMigrationDispatch | undefined
>(undefined)

export function UserMigrationContextProvider({
  children,
  state,
  dispatch
}: {
  state: UserMigrationState
  dispatch: UserMigrationDispatch
} & PropsWithChildren) {
  return (
    <UserMigrationStateContext.Provider value={state}>
      <UserMigrationDispatchContext.Provider value={dispatch}>
        {children}
      </UserMigrationDispatchContext.Provider>
    </UserMigrationStateContext.Provider>
  )
}

export function useUserMigrationState<T extends UserMigrationState>(): T {
  const state = React.useContext(UserMigrationStateContext)
  if (state === undefined) {
    throw new Error(
      'useUserMigrationState must be used within a UserMigrationContextProvider'
    )
  }
  return state as T
}

export function useUserMigrationDispatch(): UserMigrationDispatch {
  const dispatch = React.useContext(UserMigrationDispatchContext)
  if (dispatch === undefined) {
    throw new Error(
      'useUserMigrationDispatch must be used within a UserMigrationContextProvider'
    )
  }
  return dispatch
}
