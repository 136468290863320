import * as React from 'react'
import { AddIcon, ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { Layout } from './Layout'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LinkToRoute, useParamsFromRoute } from './routes'
import { useUserMigrationState } from './UserMigrationContextProvider'
import { VerifyEmail as VerifyEmailState } from './state'

export const VerifyEmail = () => {
  const { username } = useUserMigrationState<VerifyEmailState>()
  const sessionParams = useParamsFromRoute('account.index')

  return (
    <Layout>
      <div className='p-16'>
        <div className='type-headline-2 pt-10'>Check your email</div>
        <div className='pt-6 flex'>
          <div className='flex-auto w-1/2'>
            We have sent an email to{' '}
            <span className='font-bold'>{username}</span> to verify your email
            to connect your accounts.
          </div>
          <div className='flex-auto text-right'>
            <Button
              as='a'
              variant='link'
              iconRight={<ArrowForwardIcon accessibility='decorative' />}
              href='#dashboard'
            >
              Resend Email
            </Button>
          </div>
        </div>
        <hr className='mt-6 h-px border-1 bg-gray-125 mb-6' />
        <div className='flex justify-end'>
          <LinkToRoute route='account.email.verify' routeParams={sessionParams}>
            <Button
              type='button'
              variant='secondary'
              iconLeft={<AddIcon accessibility='decorative' />}
            >
              Edit Email
            </Button>
          </LinkToRoute>
        </div>
      </div>
    </Layout>
  )
}
