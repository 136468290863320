import * as React from 'react'
import { QuestionAnswerDisplayIcon } from '@toasttab/buffet-pui-icons'
import { useParamsFromRoute } from './routes'
import { Account } from './Account'
import { useUserMigrationState } from './UserMigrationContextProvider'
import { UsePayrollLogin } from './state'

export const PayrollAccount = () => {
  const { username } = useUserMigrationState<UsePayrollLogin>()

  return (
    <Account
      email={username}
      heading='Use your Payroll login email to login to all your Toast accounts'
      body={
        <>
          Connect your Payroll account with your Toast account to easily
          navigate between your accounts with single sign on.
          <br />
          We’ll need to verify it’s really you.
        </>
      }
      questions={<Questions />}
    />
  )
}

const Questions = () => (
  <div className='space-y-6'>
    <div className='flex items-start space-x-6'>
      <QuestionAnswerDisplayIcon aria-label='question and answer' />
      <div>
        <div className='font-semibold'>Can I change my login email?</div>
        <div className='type-subhead text-secondary'>
          Yes, you can change your login email, we’ll need to verify it’s really
          you!
        </div>
      </div>
    </div>

    <div className='flex items-start space-x-6'>
      <QuestionAnswerDisplayIcon aria-label='question and answer' />
      <div>
        <div className='font-semibold'>Can I use a username?</div>
        <div className='type-subhead text-secondary'>
          Unfortunately not, your personal information is more secure using an
          email.
        </div>
      </div>
    </div>
  </div>
)
