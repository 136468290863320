import { useState } from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon, EditIcon } from '@toasttab/buffet-pui-icons'
import { LinkToRoute, useParamsFromRoute } from './routes'
import { Layout } from './Layout'
import * as React from 'react'

export const Password = () => {
  const sessionParams = useParamsFromRoute('account.index')
  return (
    <Layout>
      <div className='p-16'>
        <div className='w-2/3'>
          <h1 className='type-headline-2 mb-4'>Create a new password</h1>
          <p>
            Create a new password to use to log into your Toast and Payroll
            account.
          </p>
        </div>
        <hr className='mt-6 mb-6 h-px border-1 bg-gray-125' />
        <TextInput
          type='password'
          label='Create new password'
          placeholder='Type password'
        />
      </div>
      <div className='shadow p-6 flex justify-end'>
        <LinkToRoute route='account.email.verify' routeParams={sessionParams}>
          <Button
            type='button'
            iconRight={<ArrowForwardIcon aria-label='ToastAccount accounts' />}
          >
            Continue
          </Button>
        </LinkToRoute>
      </div>
    </Layout>
  )
}
