import * as React from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Layout } from './Layout'
import { Logo } from './Logo'
import { Button } from '@toasttab/buffet-pui-buttons'
import { EmailVerified as EmailVerifiedState } from './state'
import { useUserMigrationState } from './UserMigrationContextProvider'

export const EmailVerified = () => {
  const { username } = useUserMigrationState<EmailVerifiedState>()

  return (
    <Layout>
      <div className='p-16 flex flex-col items-center'>
        <Logo />
        <h1 className='type-headline-2 mt-6'>You’re all set up!</h1>
        <p className='mt-8 mb-8 text-center max-w-sm'>
          <span className='font-bold'>{username}</span> is ready to be used to
          login to all of your Toast accounts!
        </p>
        <CelebrationIllustration />
      </div>
      <div className='shadow p-6 flex justify-end'>
        <Button type='button'>Continue</Button>
      </div>
    </Layout>
  )
}
