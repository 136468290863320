import { useState } from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { ArrowForwardIcon, EditIcon } from '@toasttab/buffet-pui-icons'
import { LinkToRoute, useParamsFromRoute } from './routes'
import { Layout } from './Layout'
import * as React from 'react'

const EmailCard = (props: { email: string }) => {
  const { email } = props
  const [editing, setEditing] = useState(false)

  if (editing) {
    return (
      <TextInput
        type='email'
        label='Email'
        placeholder='Enter your email'
        onBlur={() => setEditing(false)}
        value={email}
      />
    )
  } else {
    return (
      <CardContainer noElevation>
        <div className='flex '>
          <div className='flex-none'>
            <div className='font-bold'>Toast Login</div>
            <div>{email}</div>
          </div>
          <div className='flex-auto text-right '>
            <IconButton
              icon={<EditIcon accessibility='decorative' />}
              onClick={() => setEditing(true)}
            />
          </div>
        </div>
      </CardContainer>
    )
  }
}

export const Account = (props: {
  heading: React.ReactNode
  body: React.ReactNode
  email: string
  questions?: React.ReactNode
}) => {
  const { email, questions, heading, body } = props
  const sessionParams = useParamsFromRoute('account.index')
  return (
    <Layout questions={questions}>
      <div className='p-16'>
        <div className='w-2/3'>
          <h1 className='type-headline-2 mb-4'>{heading}</h1>
          <p>{body}</p>
        </div>
        <hr className='mt-6 mb-6 h-px border-1 bg-gray-125' />
        <EmailCard email={email} />
      </div>
      <div className='shadow p-6 flex justify-end'>
        <LinkToRoute route='account.email.verify' routeParams={sessionParams}>
          <Button
            type='button'
            iconRight={<ArrowForwardIcon aria-label='ToastAccount accounts' />}
          >
            Continue
          </Button>
        </LinkToRoute>
      </div>
    </Layout>
  )
}
