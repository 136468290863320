import { routeFactory } from '@local/react-router-extensions/route-factory'

const base = `/user/migration/:sessionId`

const {
  pathTemplate,
  LinkToRoute,
  routeToPath,
  useRouteToPath,
  useParamsFromRoute
} = routeFactory({
  'account.index': `${base}`,
  'account.toastAccount': `${base}/toast`,
  'account.payrollAccount': `${base}/payroll`,
  'account.password': `${base}/password`,
  'account.email.check': `${base}/email/choose`,
  'account.email.verify': `${base}/email/verify`,
  'account.email.verified': `${base}/email/verified`,
  'employee.overview': '/employee/:employeeId/overview'
})

export {
  pathTemplate,
  LinkToRoute,
  routeToPath,
  useRouteToPath,
  useParamsFromRoute
}
