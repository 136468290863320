import { BrowserRouter, Navigate, RouteObject, RouterProvider, createBrowserRouter, useRoutes } from 'react-router-dom'
import { pathTemplate } from './routes'
import { PayrollAccount } from './PayrollAccount'
import { EmailVerified } from './EmailVerified'
import { Password } from './Password'
import { ToastAccount } from './ToastAccount'
import { VerifyEmail } from './VerifyEmail'
import { userMigrationReducer } from './reducer'
import { useReducer } from 'react'
import { InitialState } from './state'
import { UserMigrationContextProvider } from './UserMigrationContextProvider'
import { FocusView } from '@toasttab/buffet-pui-config-templates'
import { NotFound } from './NotFound'
import { useFeature } from '@toasttab/ec-features'

const routeConfig: RouteObject[] = [
  {
    path: '*',
    element: <Navigate to={pathTemplate('account.toastAccount')} />
  },
  {
    path: pathTemplate('account.index'),
    children: [
      {
        index: true,
        element: <Navigate to={pathTemplate('account.toastAccount')} />
      },
      {
        path: pathTemplate('account.toastAccount'),
        element: <FocusView className='bg-gray-0'><ToastAccount /></FocusView>
      },
      {
        path: pathTemplate('account.payrollAccount'),
        element: <PayrollAccount />
      },
      {
        path: pathTemplate('account.email.verify'),
        element: <VerifyEmail />
      },
      {
        path: pathTemplate('account.email.verified'),
        element: <EmailVerified />
      },
      {
        path: pathTemplate('account.password'),
        element: <Password />
      }
    ]
  }
]

function Routes() {
  return useRoutes(routeConfig)
}

export function App() {
  const [state, dispatch] = useReducer(userMigrationReducer, {
    type: 'initial-state',
    username: 'user@example.com'
  } as InitialState)
  const userDrivenMigrationEnabled = useFeature('ec-integrations-enable-user-driven-migration')
  console.log(userDrivenMigrationEnabled)
  if (!userDrivenMigrationEnabled) {
    return <NotFound />
  } else {
    return (
      <UserMigrationContextProvider state={state} dispatch={dispatch}>
        <BrowserRouter >
          <Routes /> 
        </BrowserRouter>
      </UserMigrationContextProvider>
    )
  }
}
