import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'
import { Portal, PortalProvider } from '@toasttab/ec-layout'

export const NotFound = () => {
  return (
    <PortalProvider value='data-udm'>
      <Portal id='page-body' component={
      <>
        <Error500DesktopIllustration />
        <h1>Oops we have some cleaning up to do</h1>
      </>
    } />
    </PortalProvider>
  )
}
