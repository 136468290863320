import { UserMigrationState } from './state'
import * as React from 'react'

export const userMigrationReducer = (
  state: UserMigrationState,
  action: UserMigrationAction
): UserMigrationState => {
  return state
}

export type UserMigrationDispatch = React.Dispatch<UserMigrationAction>
